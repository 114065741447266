<style lang="scss" scoped>
.enter_process_pop {
	z-index: 3001 !important;
	.content {
		font-size: 0.24rem;
		color: $font_color_main;
		padding: 0.2rem 0.3rem;
	}
	.link {
		color: $color_main;
	}
	.section {
		padding: 0.2rem 0;
		text-indent: 2em;
		line-height: 0.4rem;
	}
	.footer {
		text-indent: 2em;
	}
}
.count {
	margin: 0.39rem 0.3rem 0.31rem;
	height: 0.8rem;
	border-radius: 8px;
	font-size: 0.3rem;
	text-align: center;
	color: #fff;
	background-color: $color_main;
	line-height: 0.8rem;
}
</style>

<template>
	<div>
		<van-action-sheet v-model="showPop" class="enter_process_pop" title="确认进入投保流程" @close="closeWarningPop" @cancel="cancel" @click-overlay="clickOverlay">
			<div class="content">
				<p class="header">尊敬的客户：</p>
				<p class="section">您即将进入投保流程，请仔细阅读保险条款。本保险产品由《<span class="link" v-text="name"></span>》承保，由耀保网协助理赔。</p>
				<p class="footer">投保前请你仔细阅读<a v-if="isLink" class="link" :href="fileUrl" target="_blank">客户告知书</a><slot v-else name="content"></slot></p>
			</div>
			<div class="count" @click="enterProcess">确认开始投保</div>
		</van-action-sheet>
	</div>
</template>

<script>
import { ActionSheet } from 'vant';
import { http_insDatingBack } from '@/request/common';

export default {
	name: 'enterSure', // 确定进入投保流程
	components: {
		[ActionSheet.name]: ActionSheet,
	},
	model: {
		prop: 'show',
	},
	props: {
		show: {
			type: [Boolean, Number, String],
			require: true,
		},

		// 保险公司名称
		name: {
			type: String,
			required: false,
		},

		// 告知书链接
		fileUrl: {
			type: String,
			required: false,
		},
		// 客户告知书是否是链接
		isLink: {
			type: Boolean,
			default: true,
		},

		// 产品Id,用于可回溯
		proId: {
			type: [String, Number, Array],
			required: true,
		},
	},
	data() {
		return {};
	},
	computed: {
		showPop: {
			get() {
				return this.show;
			},
			set(val) {
				this.$emit('input', val);
			},
		},
	},
	created() {},
	methods: {
		closeWarningPop() {
			this.$emit('closeWarningPop');
		},
		cancel() {
			this.$emit('cancel');
		},
		clickOverlay() {
			this.$emit('clickOverlay');
		},

		// 确定进入投保流程
		enterProcess() {
			this.sendDatingBack();
			this.$emit('confirm');
			this.showPop = false;
		},

		// 发送可回溯信息
		sendDatingBack() {
			if (Array.isArray(this.proId)) {
				this.proId.forEach(id => {
					http_insDatingBack({ content: '确认进入投保流程', proId: id });
				});
			} else {
				http_insDatingBack({ content: '确认进入投保流程', proId: this.proId });
			}
		},
	},
};
</script>
